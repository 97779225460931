import React from "react";
import logoImg from "../Assets/img/logo/logo.png";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Footer = () => {
  return (
    <footer className="bg-light py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-7 col-12 mb-4 mb-md-6 mb-lg-0 order-0 text-center">
            <img
              className="mb-4 mx-auto d-block"
              src={logoImg}
              height="85"
              alt="jadoo"
            />
            <p className="fs--1 text-secondary mb-0 fw-medium">
              Book your trip in minute, get full control for much longer.
            </p>
          </div>
          <div className="col-lg-2 col-md-4 mb-4 mb-lg-0 order-lg-1 order-md-2">
            <h4 className="footer-heading-color fw-bold font-sans-serif mb-3 ">
              Company
            </h4>
            <ul className="list-unstyled mb-0">
              <li className="mb-2">
                <a
                  className="link-900 fs-5 fw-medium text-decoration-none"
                  href="/about"
                >
                  About
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="link-900 fs-5 fw-medium text-decoration-none"
                  href="/careers"
                >
                  Careers
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-4 mb-4 mb-lg-0 order-lg-2 order-md-3">
            <h4 className="footer-heading-color fw-bold font-sans-serif mb-3 ">
              Contact
            </h4>
            <ul className="list-unstyled mb-0">
              <li className="mb-2">
                <a
                  className="link-900 fs-5 fw-medium text-decoration-none"
                  href="/help"
                >
                  Help/FAQ
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="link-900 fs-5 fw-medium text-decoration-none"
                  href="/packages"
                >
                  Packages
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="link-900 fs-5 fw-medium text-decoration-none"
                  href="/query"
                >
                  Query
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-4 mb-4 mb-lg-0 order-lg-3 order-md-4">
            <h4 className="footer-heading-color fw-bold font-sans-serif mb-3 ">
              More
            </h4>
            <ul className="list-unstyled mb-0">
              <li className="mb-2">
                <a
                  className="link-900 fs-5 fw-medium text-decoration-none"
                  href="/privacy-policy"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="link-900 fs-5 fw-medium text-decoration-none"
                  href="/refund-policy"
                >
                  Refund Policy
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="link-900 fs-5 fw-medium text-decoration-none"
                  href="/terms-conditions"
                >
                  Terms &amp; Conditions
                </a>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-5 col-12 mb-4 mb-md-6 mb-lg-0 order-lg-4 order-md-1">
            <h4 className="footer-heading-color fw-bold font-sans-serif mb-3 ">
              Follow us on
            </h4>
            <div className="icon-group mb-4">
              <button
                className="text-decoration-none icon-item shadow-social"
                id="facebook"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=61558159326775",
                    "_blank"
                  )
                }
              >
                <i className="fab fa-facebook-f"></i>
              </button>
              <button
                className="text-decoration-none icon-item shadow-social"
                id="instagram"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/wowtripss/?hl=en",
                    "_blank"
                  )
                }
              >
                <i className="fab fa-instagram"></i>
              </button>
              <button
                className="text-decoration-none icon-item shadow-social"
                id="whatsapp"
                onClick={() =>
                  window.open("https://wa.me/7042151535", "_blank")
                }
              >
                <i className="fab fa-whatsapp"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
