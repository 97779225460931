import "./global.css";
import CarouselComp from "./Components/CarouselComp";
import DestinationSection from "./Components/DestinationSection";
import Header from "./Components/Header";
import Services from "./Components/Services";
import Contact from "./Components/Contact";
//import Testimonial from "./Components/Testimonial";
import Footer from "./Components/Footer";
import Copyright from "./Components/Copyright";
import WelcomePopup from "./Components/WelcomePopup";

function App() {
  return (
    <main className="main" id="top">
      <WelcomePopup />
      <Header />
      <CarouselComp />
      <Services />
      <DestinationSection />
      <Contact />
      <Footer />
      <Copyright />
    </main>
  );
}

export default App;
